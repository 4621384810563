import cn from "clsx";
import {
    ComponentPropsWithoutRef,
    createElement,
    forwardRef,
    HTMLAttributes,
    PropsWithChildren,
} from "react";
import styles from "./Menu.module.scss";

type ValidHTMLTags = "a" | "button" | "span";
type ReactComponent = Exclude<React.ElementType, keyof JSX.IntrinsicElements>;
export type ValidTags = ValidHTMLTags | ReactComponent;
export type ValidWrapperTags = "li" | "div" | "span";

export type MenuItemProps<
    T extends ValidTags = "button",
    U extends ValidWrapperTags = "li",
> = ComponentPropsWithoutRef<T> &
    HTMLAttributes<HTMLOrSVGElement> &
    PropsWithChildren & {
        /**
         * Icon displayed to the left
         */
        adornLeft?: React.ReactNode;

        /**
         * Badge displayed to the right
         */
        adornRight?: React.ReactNode;

        /**
         * Is the adornRight Justified to the right or the left?
         * @default left
         */
        justifyAdornRight?: "right" | "left";
        /**
         * Optional classname for the menu item wrapper element
         */
        wrapperClassName?: string;

        /**
         * Alternate JSX element to use for the button
         * @default button
         */
        tag?: T | ValidTags;
        /**
         * Alternate JSX element to use for the wrapper type
         * @default li
         */
        wrapperTag?: U | ValidWrapperTags;
    };

export const DEFAULT_TAG = "button" as const;
export const DEFAULT_BASE_TAG = "li" as const;

export const InnerMenuItem = <T extends ValidTags, U extends ValidWrapperTags>(
    {
        adornLeft,
        adornRight,
        children,
        className,
        justifyAdornRight = "left",
        tag = DEFAULT_TAG,
        wrapperTag = DEFAULT_BASE_TAG,
        wrapperClassName,
        ...htmlProps
    }: MenuItemProps<T, U> & { href?: string },
    ref: React.ForwardedRef<HTMLOrSVGElement>,
) => {
    const menuItemWrapperClassName = cn(
        styles["menu-item-wrapper"],
        wrapperClassName,
    );
    const menuItemClassName = cn(styles["menu-item"], className);
    return createElement(
        wrapperTag,
        { className: menuItemWrapperClassName },
        createElement(
            tag,
            {
                ...htmlProps,
                className: menuItemClassName,
                ref,
                type: tag === "button" ? "button" : undefined,
            },
            <>
                {adornLeft && (
                    <span className={styles["menu-item-icon"]}>
                        {adornLeft}
                    </span>
                )}
                <span
                    className={cn(
                        styles["menu-item-text"],
                        styles[`menu-item-text-${justifyAdornRight}`],
                    )}
                >
                    {children}
                </span>
                {adornRight && (
                    <span
                        className={cn(
                            styles["menu-item-badge"],
                            styles[`menu-item-badge--${justifyAdornRight}`],
                        )}
                    >
                        {adornRight}
                    </span>
                )}
            </>,
        ),
    );
};

export const MenuItem = forwardRef(InnerMenuItem);
