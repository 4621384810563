"use client";
import { LoadingSkeleton, Text } from "@wojo/ui";
import React, { HtmlHTMLAttributes, Suspense } from "react";
import {
    useContactInfoContentSuspenseQuery,
    BusinessCode,
} from "../../generated/graphql";
import { ErrorBoundary } from "react-error-boundary";
import { useI18n } from "@wojo/localization";

export const ContactAddressContent: React.FC<
    HtmlHTMLAttributes<HTMLDivElement>
> = (props) => {
    const { data: contactInfo } = useContactInfoContentSuspenseQuery({
        variables: {
            language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE ?? "",
            businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
        },
        context: {
            fetchOptions: { next: { revalidate: 900 } },
            includeCookie: false,
        },
    });
    const address = contactInfo?.contactInfoContent?.address;
    if (!address) {
        return null;
    }
    return (
        <Text.Body {...props} tag="address">
            {address.addressName}
            <br />
            {address.street}
            <br />
            {`${address.city}, ${address.state} ${address.zip}`}
        </Text.Body>
    );
};

export const ContactAddress: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (
    props,
) => {
    const loadingLabel = useI18n("global").global.loadingStates.address;
    return (
        <ErrorBoundary fallback={null}>
            <Suspense
                fallback={
                    <LoadingSkeleton
                        accessibleText={loadingLabel.l()}
                        height="3lh"
                    />
                }
            >
                <ContactAddressContent {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};
