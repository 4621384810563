"use client";

import { Button, ButtonProps } from "@wojo/ui";
import React, { PropsWithChildren, useEffect } from "react";
import { useCreateHelpCenterLoginTokenMutation } from "../generated/graphql";

export type ZendeskHelpCenterLinkProps = PropsWithChildren<
    Omit<ButtonProps<"a">, "tag">
>;

export const ZendeskHelpCenterLink: React.FC<ZendeskHelpCenterLinkProps> = ({
    href,
    children,
    ...props
}) => {
    const [createToken, { data }] = useCreateHelpCenterLoginTokenMutation({
        errorPolicy: "all",
    });
    const token = data?.createHelpCenterLoginToken?.token;
    const zendeskUrl = href || process.env.NEXT_PUBLIC_ZENDESK_HELP_CENTER_URL;
    const helpCenterUrl =
        token && !!process.env.NEXT_PUBLIC_ZENDESK_ACCESS_LINK
            ? `${process.env.NEXT_PUBLIC_ZENDESK_ACCESS_LINK}/jwt?jwt=${token}&return_to=${zendeskUrl}`
            : zendeskUrl;

    useEffect(() => {
        createToken();
    }, [createToken]);
    if (!helpCenterUrl) {
        return null;
    }
    return (
        <Button
            href={helpCenterUrl}
            tag="a"
            variant="quiet"
            {...props}
            target="_blank"
            rel="noopener"
        >
            {children}
        </Button>
    );
};
