import styles from "./Avatar.module.scss";

export type AvatarProps = React.PropsWithChildren<{
    /**
     * The alt text to display
     */
    altText?: string;
    /**
     * The initials to use in the avatar when no src is provided
     */
    initials?: string;
    /**
     * Pixel size of the height and width of the circle
     */
    size?: number;
    /**
     * The source of the background image
     */
    src?: string;
}>;

const determineFont = (size: number) => {
    if (size >= 80) {
        return "xl";
    } else if (size >= 60) {
        return "lg";
    } else if (size >= 50) {
        return "md";
    } else if (size >= 30) {
        return "sm";
    } else {
        return "xs";
    }
};

export const Avatar: React.FC<AvatarProps> = ({
    altText,
    initials,
    size = 50,
    src,
}) => {
    let formattedInitials = initials;
    let textClassName = "";
    if (initials && !src) {
        const fontSize = determineFont(size);
        textClassName = `${styles.text} ${styles[`font--${fontSize}`]}`;
        if (fontSize === "xs") {
            formattedInitials = initials.slice(0, 1);
        }
    }

    return (
        <>
            <span
                className={`${styles.container} ${textClassName}`}
                style={{
                    minWidth: `${size}px`,
                    minHeight: `${size}px`,
                    backgroundImage: src ? `url(${src})` : undefined,
                }}
                aria-hidden
            >
                {!src && formattedInitials}
            </span>
            {!!altText && <span className="visually-hidden">{altText}</span>}
        </>
    );
};
