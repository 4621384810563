"use client";

import { Theme, WojoExternalAccreditation } from "@wojo/storyblok";
import { Image, useComputedStyleObserver } from "@wojo/ui";
import styles from "./ExternalAccreditation.module.scss";

type ExternalAccreditationProps = {
    logoContent: WojoExternalAccreditation;
};

export const ExternalAccreditation: React.FC<ExternalAccreditationProps> = ({
    logoContent,
}) => {
    const theme = useComputedStyleObserver("--g-ui-theme");
    const prefersDarkTheme =
        theme === Theme.dark && !!logoContent.darkModeImageSrc;
    const imageForCurrentTheme = prefersDarkTheme
        ? logoContent.darkModeImageSrc
        : logoContent.lightModeImageSrc;

    if (!imageForCurrentTheme) {
        return null;
    }

    if (logoContent.link) {
        return (
            <a
                href={logoContent.link}
                target="_blank"
                rel="nofollow"
                className={styles.link}
            >
                <Image
                    src={imageForCurrentTheme}
                    alt={logoContent.imageAlt}
                    className={styles.logo}
                />
            </a>
        );
    } else {
        return (
            <Image
                src={imageForCurrentTheme}
                alt={logoContent.imageAlt}
                className={styles.logo}
            />
        );
    }
};
