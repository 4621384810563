"use client";

import { useComputedStyleObserver } from "@wojo/ui";
import { Theme } from "../../types/theme";
import { StoryblokLink } from "../../types/storyblok/storyblok-link";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { getCleanStoryblokLink } from "../../client/get-clean-storyblok-link";

type AssetLinkProps = {
    link: StoryblokLink;
    darkModeAsset?: AssetStoryblok;
    lightModeAsset?: AssetStoryblok;
    linkClassName?: string;
    assetClassName?: string;
};

export const AssetLink: React.FC<AssetLinkProps> = ({
    link,
    darkModeAsset,
    lightModeAsset,
    linkClassName,
    assetClassName,
}) => {
    const theme = useComputedStyleObserver("--g-ui-theme");
    const prefersDarkTheme = theme === Theme.dark;
    const assetToShow = prefersDarkTheme ? darkModeAsset : lightModeAsset;
    const { href, isExternal } = getCleanStoryblokLink(link);
    const [target, rel] = isExternal
        ? ["_blank", "noopener"]
        : [undefined, undefined];

    if (!assetToShow?.filename || !assetToShow.alt) {
        return null;
    }

    return (
        <a className={linkClassName} href={href} target={target} rel={rel}>
            <img
                src={assetToShow.filename}
                alt={assetToShow.alt}
                className={assetClassName}
            />
        </a>
    );
};
