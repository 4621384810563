"use client";
import { gtmSendEvent } from "@wojo/analytics";
import { Card, Form, FormTextField, SubmitButton, Text } from "@wojo/ui";
import { WojoFooterConfig } from "@wojo/storyblok";
import { useSubmitWebform } from "@wojo/webforms/src/use-submit-webform";
import { useId, useState } from "react";
import styles from "./EmailSignUp.module.scss";

type EmailSignUpProps = {
    footerContent?: WojoFooterConfig;
};

type FormType = {
    email: string;
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+([\.-]?\w+)*)+$/;

export const EmailSignUp: React.FC<EmailSignUpProps> = ({ footerContent }) => {
    const headingId = useId();
    const [success, setSuccess] = useState(false);

    if (!footerContent?.emailSignUpTitle) {
        return null;
    }

    return (
        <section aria-labelledby={headingId}>
            <Card className={styles.card}>
                <Text.Heading
                    order="3"
                    tag="h2"
                    className={styles.heading}
                    id={headingId}
                >
                    {footerContent.emailSignUpTitle}
                </Text.Heading>
                {success ? (
                    <SuccessView />
                ) : (
                    <SignUpForm onSuccess={() => setSuccess(true)} />
                )}
            </Card>
        </section>
    );
};

type SignUpFormProps = {
    onSuccess: () => void;
};

const SignUpForm: React.FC<SignUpFormProps> = ({ onSuccess }) => {
    const submitWebform = useSubmitWebform();

    return (
        <Form<FormType>
            className={styles.form}
            onSubmit={async (values: FormType) => {
                const response = await submitWebform({
                    department: "Indies",
                    formName: "email-signup",
                    email: values.email,
                });

                if (response.ok) {
                    onSuccess();
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LEAD_FORM",
                            ga4_action: "SUBMITTED",
                            leadFormId: "email-signup",
                        },
                    });
                } else {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LEAD_FORM",
                            ga4_action: "SUBMISSION_FAILED",
                            leadFormId: "email-signup",
                        },
                    });
                }
            }}
        >
            <FormTextField
                name="email"
                label="Email address"
                hideLabel
                placeholder="Email address"
                aria-required
                hideMarker
                validation={{
                    required: "Please enter your email address.",
                    pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email address.",
                    },
                }}
            />
            <SubmitButton className={styles.submit}>Submit</SubmitButton>
        </Form>
    );
};

const SuccessView: React.FC = () => {
    return (
        <Text.Body>
            You have successfully subscribed to our email list.
        </Text.Body>
    );
};
